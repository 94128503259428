const buttonCollection = document.querySelectorAll(".blocks-item__link"),
  imgCollection = document.querySelectorAll(".blocks-item__img");

function changescale(e) {
  for (i = 0; i < buttonCollection.length; i++) buttonCollection[i] == e.target && (imgCollection[i].style.transform = "scale(1.2)");
  e.stopPropagation()
}

function returnscale() {
  imgCollection.forEach((function (e, t) {
    imgCollection[t].style.transform = "scale(1)"
  }))
}
document.querySelector(".services").addEventListener("mouseover", changescale, !1), document.querySelector(".services").addEventListener("mouseout", returnscale, !1);
